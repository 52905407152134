export default {
  methods: {
    // 備考を追加
    addTextareaHandler() {
      if (this.formDuplicate.routeRemarksList >= 5) return;
      let newId;
      if (
        this.formDuplicate.routeRemarksList == null ||
        this.formDuplicate.routeRemarksList.length === 0
      ) {
        newId = 1;
      } else {
        newId =
          this.formDuplicate.routeRemarksList[this.formDuplicate.routeRemarksList.length - 1].id +
          1;
      }
      this.formDuplicate.routeRemarksList.push({
        id: newId,
        remarks: "",
      });
      this.validate.routeRemarksList.push({
        isValid: true,
        errMsg: ""
      });
    },
    // 備考削除
    removeTextareaHandler(id) {
      this.formDuplicate.routeRemarksList = this.formDuplicate.routeRemarksList.filter(
        (ta) => ta.id != id
      );
      this.validate.routeRemarksList.pop();
    },
  },
};
