export default {
  data() {
    return {
      formDuplicate: {
        isChangeStore: true,
        plansQuantity: 0,
        plansWasteUnitTypeId: 0,
        jwnetItemTypeId: 0,
        cbaItemId: 0,
        wasteNameId: 0,
        limitQuantity: 0,
        limitWasteUnitTypeId: 0,
        quantityConfirmBusinessTypeId: 0,
        shapeTypeId: 0,
        shapeQuantity: 0,
        disposalWayTypeId: null,
        harmfulInfo: [
          {
            harmfulTypeId: 0
          }
        ],
        collectUnitPriceList: [
          {
            sectionNo: 1,
            collectUnitPrice: 0,
            collectPriceWasteUnitTypeId: 0
          }
        ],
        collectTargetQuantityTypeId: 0,
        disposalTargetQuantityTypeId: 0,
        routeContactNoList: [],
        routeRemarksList: [
          {
            id: null,
            remarks: ""
          }
        ],
        wdsFileName: "",
        finalDisposalSiteList: [],
        labelList: [],
        isAutoApproval: true,
        isAutoIssueAfterCollectionDate: true,
        autoIssueExecDateTypeId: null,
        autoIssueExecTime: "",
        isAutoIssue: true,
        isNecessityCommonCollectReport: null,
        isCollectReportAlert: true,
        isDisposalReportAlert: true,
        isActive: true,
        registMemberName: "",
        generateMemberName: "",
        collectorRegistType: 0,
      },
    };
  },
};
