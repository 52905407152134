import { render, staticRenderFns } from "./FinalDisposalSiteSelectModal.vue?vue&type=template&id=4495de8c&"
import script from "./FinalDisposalSiteSelectModal.vue?vue&type=script&lang=js&"
export * from "./FinalDisposalSiteSelectModal.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports